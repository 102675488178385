import React, {Suspense, useEffect} from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { CContainer, CSpinner } from "@coreui/react";

// routes config
import routes from "../routes";
import {useAuthStore} from "../store/auth";
const AppContent = () => {

    let token = localStorage.getItem("mistToken")
    useEffect(() => {
        let token = localStorage.getItem("mistToken")
    }, [localStorage])
  return (
    <CContainer lg>
      <Suspense fallback={<CSpinner color='primary' />}>
        <Routes>
          {routes.map((route, idx) => {
            return (
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={
                      token !== null ? (
                      <route.element />
                    ) : (
                      <Navigate to='/login' />
                    )
                  }
                />
              )
            );
          })}
          <Route path='/' element={<Navigate to='/dashboard' replace />} />
        </Routes>
      </Suspense>
    </CContainer>
  );
};

export default React.memo(AppContent);
