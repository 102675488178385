import create from "zustand";
import {persist} from "zustand/middleware";

export const useAuthStore = create((set) => (
    persist({
        user: {},
        token: "",
        setUser: (props) => set({ user: props }),
        setToken: (props) => set({ token: props }),
      }, {
        name: 'UserStore',
        getStorage: () => localStorage
      }
    )
  )
);
